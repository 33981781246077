/* @import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300&family=Lobster&display=swap"); */

.App {
  text-align: center;
  padding: 0;
  height: auto;
  width: 100%;
  font-family: 'Kalam', cursive;
  transition: all 2s 1s;
  overflow: auto;
}

.App .home {
  background: linear-gradient(90deg, #a2bad4 13%, #23548a 70%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 402px) {

	.App {
		/* min-height: 800px; */
	}
}

.App.mobile-landscape {
	min-height: auto;
}
