@keyframes spin {
	0% {
		transform: rotate(359deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

.spinner-svg {
	animation: spin 2s linear infinite;
	height: 40%;
}

.spinner-svg g {
	fill: #0031fffc;
}

.spinner-container {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgb(88, 118, 199);
	opacity: .9;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 20;
}

.fade-spinner {
	animation: fadeSpinner 2s linear;
}

@keyframes fadeSpinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
