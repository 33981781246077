.entry-fields {
	margin-bottom: 1rem;
}
.entry-form {
	width: 30%;
}

.dgr-html-element {
	font-family: Georgia, 'Times New Roman', Times, serif;
	width: 30%;
	margin-left: auto;
	margin-right: auto;
	text-align: initial;
}

.log-entry-rich-edit {
	/* height: 20rem; */
	    overflow: auto;
}

.log-entry-rich-edit div[class^='RichTextEditor__editor'] {
	height: 20rem;
	overflow: auto;
}